import { graphql } from 'gatsby'
import React from 'react'

import { IBlogInfo } from '../../graphql/modules/blog'

import BlogList from '../../components/BlogList'
import { SEO } from '../../components/SEO'
import { Section } from '../../components/Section'
import { Heading } from '../../components/typography'
import FooterSection from '../../containers/FooterSection'
import StickyCTASection from '../../containers/StickyCTASection'
import { IMetadata } from '../../graphql/sections/metadata'
import { SectionContainer } from '../../styles/containerStyles'

interface IProps {
  data: {
    allContentfulBlog: {
      nodes: IBlogInfo[]
    }
  }
}

const headerStyles = {
  lineHeight: '1.65',
}

const SEO_DATA: IMetadata = {
  __typename: 'ContentfulMetadata',
  contentful_id: 'CONSTANT',
  title: 'The Well: Archives',
  slug: '/blog/archive',
  canonicalLink: 'https://borrowell.com/blog/archive',
}

const Archive: React.FC<IProps> = ({
  data: {
    allContentfulBlog: { nodes: allBlogs },
  },
}) => {
  return (
    <SectionContainer>
      <Section title={'The Well archive'}>
        <Heading styleOverrides={headerStyles}>The Well: Archives</Heading>
        <BlogList allBlogs={allBlogs} />
      </Section>
      <SEO data={SEO_DATA} />
      <StickyCTASection />
      <FooterSection />
    </SectionContainer>
  )
}

export const pageQuery = graphql`
  query archiveData {
    allContentfulBlog(sort: { fields: date, order: DESC }) {
      nodes {
        ...BlogInfoFragment
      }
    }
  }
`

export default Archive
