import React from 'react'
import styled from '@emotion/styled'

import mq from '../styles/breakpoints'
import { COLORS } from '@borrowell/bw-styles'

import { Link } from './Link'
import { Heading, Paragraph } from './typography'

import { IBlogInfo } from '../graphql/modules/blog'

interface IBlogPost {
  blog: IBlogInfo
}

interface IBlogList {
  allBlogs: IBlogInfo[]
}

const Container = styled.div`
  ${mq({
    display: 'flex',
    flexWrap: 'wrap',
  })}
`

const BlogWrapper = styled.div`
  ${mq({
    width: ['100%', '50%', '33%'],
    padding: '15px',
  })}
`

const PublishingInfoSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0 0 0;
`

const linkStyles = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  border: `2px solid ${COLORS.PURPLE['700']}`,
  borderRadius: '8px',
  padding: '5px 15px',
  height: '100%',
  ':hover,:focus,:active': {
    outline: 'none',
    backgroundColor: COLORS.PURPLE['050'],
    border: `2px solid ${COLORS.NEUTRAL.COOL['900']}`,
    h5: {
      color: COLORS.PRIMARY.DEFAULT,
    },
    ':focus': {
      boxShadow: `inset 0px 0px 0px 2px ${COLORS.NEUTRAL.COOL['900']}`,
    },
  },
}

const linkTextStyles = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  justifyContent: 'space-between',
}

const paragraphStyles = {
  margin: 0,
}

const headerStyles = {
  textAlign: 'left',
  lineHeight: '1.44',
}

const BlogPost: React.FC<IBlogPost> = ({ blog }) => {
  return (
    <BlogWrapper>
      <Link
        textStyles={linkTextStyles}
        styleOverrides={linkStyles}
        to={blog.link.link}
        rel={blog.link.rel}
        contentfulId={blog.contentful_id}
        typeName={blog.__typename}
      >
        <Heading as="h5" styleOverrides={headerStyles}>
          {blog.title}
        </Heading>
        <PublishingInfoSection>
          <Paragraph styleOverrides={paragraphStyles}>
            {blog.authorBio?.title}
          </Paragraph>
          <Paragraph styleOverrides={paragraphStyles}>{blog.date}</Paragraph>
        </PublishingInfoSection>
      </Link>
    </BlogWrapper>
  )
}

const BlogList: React.FC<IBlogList> = ({ allBlogs }) => {
  return (
    <Container>
      {allBlogs.map((blog, index) => (
        <BlogPost key={`${index}-${blog.title}`} blog={blog} />
      ))}
    </Container>
  )
}

export default BlogList
